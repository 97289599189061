import React, { useEffect, useState } from "react"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { timeAgoShort } from "@/util/timeago"
import Avatar from "@/components/Messenger/Avatar"
import Sifter from "sifter"
import useDebounce from "@/util/useDebounce"
import { castPerson } from "@/components/Messenger/util"

function ConversationCard(props) {
  return (
    <div
      className={
        "conversation-card " +
        (props.isActive ? "conversation-card--is-active" : "")
      }
      onClick={() => {
        props.onClick(props)
      }}
      hidden={!props.isActive && props.isDeleted}
    >
      <div className="conversation-card__avatar">
        <Avatar unread={props.unread} {...props.person} />
      </div>
      <div className="conversation-card__title">
        {props.title}
        <div className="conversation-card__subtitle">
          {props.subtitle}
          {props.isDeleted && (
            <div className="conversation__title-right">
              <div className="danger">DELETED</div>
            </div>
          )}
        </div>
      </div>
      <div className="conversation-card__time-ago">
        {props.date && timeAgoShort(props.date)}
      </div>
    </div>
  )
}

function NewConversations({ search, searchUrl, startConversation } = props) {
  const [people, setPeople] = useState([])
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    if (search.length == 0) {
      setPeople([])
    } else {
      const location = window.location
      const url = new URL(
        `${location.protocol}//${location.host}${searchUrl}.json`
      )
      url.searchParams.append("query", debouncedSearch)
      url.searchParams.append("without_conversation", "true")
      url.searchParams.append("with_sms_enabled_phone_number", "true")
      fetch(url.toString())
        .then(body => body.json())
        .then(json =>
          json.map(p => {
            let nickname = ""
            if (p.nickname) {
              nickname = `"${p.nickname}"`
            }
            return {
              title: `${p.first_name} ${nickname} ${p.last_name}`,
              person: castPerson(p),
            }
          })
        )
        .then(json => setPeople(json))
    }
  }, [debouncedSearch])

  if (people.length) {
    return (
      <div className="messenger__new-conversations">
        <h5 className="messenger__new-conversations-title">
          Start a new conversation with...
        </h5>
        {people.map((p, i) => (
          <ConversationCard key={i} {...p} onClick={startConversation} />
        ))}
      </div>
    )
  } else {
    return null
  }
}

const ALL_CONVERSATIONS = "All Conversations"
const UNASSIGNED = "Unassigned"

export default class ConversationList extends React.Component {
  state = { search: "", activeChat: null, selectedUserId: ALL_CONVERSATIONS }

  navigateTo = chat => {
    if (this.state.activeChat != chat.id) {
      this.setState({ activeChat: chat.id })
      this.props.history.push(`/conversation/${chat.id}`)
    } else {
      console.log("Loading Conversation")
    }
  }

  componentDidMount() {
    if (this._currentUserHasASubscription()) {
      this.setState({ selectedUserId: this.props.currentUser.id })
    }
    const activeCard = this.refs.cards.getElementsByClassName(
      "conversation-card--is-active"
    )[0]
    if (activeCard) {
      console.log("[ConversationList] Scroll to show active card", activeCard)
      const amountOfPreviousCardToShow = 30
      this.refs.cards.scrollTop =
        activeCard.offsetTop - amountOfPreviousCardToShow
    }
  }

  updateSearch = event => {
    this.setState({ search: event.target.value })
  }

  updateSelectedUser = option => {
    this.setState({ selectedUserId: option.value })
  }

  filteredConversations() {
    var sifter = new Sifter(this.props.conversations)
    const result = sifter.search(this.state.search, { fields: ["title"] })
    const indexes = result.items.map(i => i.id)
    const conversationsFilteredByTitle = indexes.map(
      i => this.props.conversations[i]
    )
    if (this.state.selectedUserId == ALL_CONVERSATIONS) {
      return conversationsFilteredByTitle
    } else if (this.state.selectedUserId == UNASSIGNED) {
      return conversationsFilteredByTitle.filter(
        c => c.subscriptions.length == 0
      )
    } else {
      return conversationsFilteredByTitle.filter(
        c =>
          c.subscriptions.filter(s => s.user_id == this.state.selectedUserId)
            .length > 0
      )
    }
  }

  userOptions() {
    const unassignedUser = { label: UNASSIGNED, value: UNASSIGNED }
    const userIds = new Set()
    const userOptions = new Set()
    userIds.add(this.props.currentUser.id)
    userOptions.add(this._buildUserOption(this.props.currentUser))
    this.props.conversations.forEach((conversation, _) => {
      conversation.subscriptions.forEach((subscription, _) => {
        const user = subscription.user
        const userOption = this._buildUserOption(user)
        if (!userIds.has(user.id)) {
          userIds.add(user.id)
          userOptions.add(userOption)
        }
      })
      if (conversation.subscriptions.length == 0) {
        userOptions.add(unassignedUser)
      }
    })
    const sortedUsers = Array.from(userOptions).sort((a, b) =>
      a.label.localeCompare(b.label)
    )
    return [{ label: ALL_CONVERSATIONS, value: ALL_CONVERSATIONS }].concat(
      sortedUsers
    )
  }

  render() {
    const conversationCards = this.filteredConversations().map(c => {
      const isActive = this.props.location.pathname.match(
        `/conversation/${c.id}(\/|$)`
      )
      const isDeleted = c.discarded_at

      return (
        <ConversationCard
          key={c.id}
          isActive={isActive}
          onClick={this.navigateTo}
          isDeleted={isDeleted}
          {...c}
        />
      )
    })
    return (
      <div className="messenger__conversations">
        <h3 className="messenger__conversations-title">
          Conversations
          <span className="messenger__conversations-user-filter">
            <Select
              className={this.props.readOnly ? "__locked" : ""}
              disabled={this.props.readOnly}
              onChange={this.updateSelectedUser}
              options={this.userOptions()}
              value={this.userOptions().find(
                user => user.value == this.state.selectedUserId
              )}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
          </span>
        </h3>
        <div className="messenger__conversations-search">
          <FontAwesomeIcon
            className="messenger__conversations-search-icon"
            icon="search"
          />
          <input
            className={this.props.readOnly ? "__locked" : ""}
            disabled={this.props.readOnly}
            type="search"
            value={this.state.search}
            onChange={this.updateSearch}
            placeholder="Search/start a conversation..."
          />
        </div>
        <div className="messenger__conversations-cards" ref="cards">
          {conversationCards}
          <NewConversations
            searchUrl={this.props.searchUrl}
            search={this.state.search}
            startConversation={event => {
              this.setState({ search: "" })
              this.props.startConversation(event, this.props.history)
            }}
          />
        </div>
      </div>
    )
  }

  _allSubscriptionUsers() {
    return this.props.conversations.flatMap(conversation =>
      conversation.subscriptions.map(subscription => subscription.user)
    )
  }

  _buildUserOption(user) {
    return {
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
    }
  }

  _currentUserHasASubscription() {
    return this._allSubscriptionUsers().find(
      user => user.id == this.props.currentUser.id
    )
  }
}
